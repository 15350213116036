<template>
  <div class="nk-block nk-block-middle nk-auth-body wide-xs mt-5">
    <div class="brand-logo pb-4 text-center">
      <a href="" class="logo-link w3-block">
        <img
          class="logo-light logo-img logo-img-lg"
          src="images/logo.png"
          srcset="images/logo2x.png 2x"
          alt="logo"
        />
        <img
          class="logo-dark logo-img logo-img-lg"
          src="images/logo-dark.png"
          srcset="images/logo-dark2x.png 2x"
          alt="logo-dark"
        />
      </a>
    </div>
    <div class="card card-bordered">
      <div class="card-inner card-inner-lg">
        <form v-if="!TokenSent" @submit.prevent="SendToken" method="post">
          <div class="nk-block-head">
            <div class="nk-block-head-content">
              <h5 class="nk-block-title">Reset password</h5>
              <div class="nk-block-des">
                <p>
                  If you forgot your password, well, then we’ll email you a
                  token to reset your password.
                </p>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label-group">
              <label class="form-label" for="default-01">Email</label>
            </div>
            <input
              v-model="ContactNo"
              type="text"
              class="form-control form-control-lg"
              placeholder="Enter your email address"
            />
          </div>
          <div class="form-group">
            <button
              :disabled="!ContactNo"
              class="btn btn-lg btn-primary btn-block"
              type="submit"
            >
              Send Reset Link
            </button>
          </div>
        </form>
        <form
          v-if="TokenSent && !TokenVerified"
          @submit.prevent="VerifyToken"
          method="post"
        >
          <div class="nk-block-head">
            <div class="nk-block-head-content">
              <h5 class="nk-block-title">Reset password</h5>
              <div class="nk-block-des">
                <p>Enter the reset token sent to your email to proceed</p>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label-group">
              <label class="form-label" for="default-01">Enter token</label>
            </div>
            <input
              v-model="Token"
              type="text"
              class="form-control form-control-lg"
              placeholder="Enter token"
            />
          </div>
          <div class="form-group">
            <button
              :disabled="!Token"
              class="btn btn-lg btn-primary btn-block"
              type="submit"
            >
              Verify
            </button>
          </div>
        </form>
        <form
          v-if="TokenVerified"
          @submit.prevent="UpdatePassword"
          method="post"
        >
          <div class="nk-block-head">
            <div class="nk-block-head-content">
              <h5 class="nk-block-title">Set new password</h5>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label-group">
              <label class="form-label" for="default-01"
                >Enter a new password</label
              >
            </div>
            <input
              v-model="NewPassword"
              type="text"
              class="form-control form-control-lg"
              placeholder="New Password"
            />
          </div>
          <div class="form-group">
            <div class="form-label-group">
              <label class="form-label" for="default-01"
                >Repeat new password</label
              >
            </div>
            <input
              v-model="RePassword"
              type="text"
              class="form-control form-control-lg"
              placeholder="Repeat Password"
            />
          </div>
          <div class="form-group">
            <button
              :disabled="!NewPassword || !RePassword"
              class="btn btn-lg btn-primary btn-block"
              type="submit"
            >
              Update Password
            </button>
          </div>
        </form>
        <div class="form-note-s2 text-center pt-4">
          <a href="#" @click.prevent="CancelReset"
            ><strong>Return to login</strong></a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { Util } from "@/helpers/utilities";
import axios from "axios";
export default {
  components: {},
  setup(_, { emit }) {
    const TokenSent = ref(false);
    const ContactNo = ref("");
    const Token = ref("");
    const TokenVerified = ref(false);
    const NewPassword = ref("");
    const RePassword = ref("");
    const PasswordChanged = ref(false);

    const CancelReset = () => {
      emit("cancelPasswordReset");
      TokenSent.value = false;
      TokenVerified.value = false;
      ContactNo.value = "";
      Token.value = "";
    };
    const Completed = () => {
      emit("completedPasswordReset");
      TokenSent.value = false;
      TokenVerified.value = false;
      ContactNo.value = "";
      Token.value = "";
    };
    const w = 1;
    const SendToken = () => {
      if (w === 1) {
        TokenSent.value = true;
        Util.Success(
          "A password reset token have been sent to your email. Please use the token to reset your password."
        );
        return;
      }
      if (!ContactNo.value) {
        Util.Error("Please enter your Account number to continue");
        return;
      }
      Util.showIndicator();
      axios({
        method: "get",
        url: "/api/Security/SendPasswordToken",
        params: { UserID: ContactNo.value },
      }).then((response) => {
        Util.hideIndicator();
        if (response.data.status === "success") {
          TokenSent.value = true;
          Util.Success(response.data.result);
        } else {
          TokenSent.value = false;
          Util.Error(
            response.data.result ? response.data.result : "An error occured"
          );
        }
      });
    };

    const VerifyToken = () => {
      if (w === 1) {
        TokenVerified.value = true;
        // Util.Success("Token verified");
        return;
      }
      if (!ContactNo.value) {
        Util.Error("Please enter your Account number to continue");
        return;
      }
      if (!Token.value) {
        Util.Error("Please enter the token received to continue");
        return;
      }
      TokenVerified.value = false;
      Util.showIndicator();
      axios({
        method: "post",
        url: "/api/Security/VerifyToken",
        data: { UserID: ContactNo.value, otp: Token.value },
      }).then((response) => {
        Util.hideIndicator();
        if (response.data.status === "success") {
          TokenVerified.value = true;
          // Util.Success(response.data.result);
        } else {
          Util.Error(response.data.result);
        }
      });
    };
    const UpdatePassword = () => {
      if (w === 1) {
        PasswordChanged.value = true;
        Util.Success(
          "<h4>Password changed successfully</h4>You can now login with the new password"
        );
        Completed();
        return;
      }
      if (!ContactNo.value) {
        Util.Error("Please enter your Account number to continue");
        return;
      }
      if (!NewPassword.value) {
        Util.Error("Please enter your new password to continue");
        return;
      }
      if (!RePassword.value) {
        Util.Error("Please repeat your new password to continue");
        return;
      }
      if (NewPassword.value !== RePassword.value) {
        Util.Error("The new and repeated password mismatch");
        return;
      }
      PasswordChanged.value = false;
      Util.showIndicator();
      axios({
        method: "post",
        url: "/api/Security/UpdatePassword",
        data: { UserID: ContactNo.value, NewPassword: NewPassword.value },
      }).then((response) => {
        Util.hideIndicator();
        if (response.data.status === "success") {
          PasswordChanged.value = true;
          Util.Success(
            "Password updated successfully. You can now login with the new password"
          );
          Completed();
        } else {
          Util.Error(response.data.result);
        }
      });
    };
    return {
      SendToken,
      ContactNo,
      TokenSent,
      Token,
      VerifyToken,
      TokenVerified,
      NewPassword,
      RePassword,
      UpdatePassword,
      PasswordChanged,
      CancelReset,
    };
  },
};
</script>
