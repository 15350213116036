<template>
  <teleport to="#bgTeleports">
    <div class="slideshow-container">
      <div
        v-for="(n, i) in nums"
        :key="n"
        class="bgSlides"
        :class="['slide-' + i]"
        :style="[
          'background-image: url(media/images/bg-' + n + '.jpg);',
          i > 0 ? 'display: none;' : '',
        ]"
      ></div>
    </div>
  </teleport>
</template>

<script>
import { onMounted, ref } from "vue";
import $ from "jquery";
import { Util } from "../helpers/utilities";
export default {
  setup() {
    var slideIndex = ref(0);
    const nums = Util.shuffleArray([1, 2, 3, 4, 5, 6]);
    function showSlides() {
      const numSlides = $(".bgSlides").length;
      let currentSlideIndex = slideIndex.value;
      slideIndex.value++;
      if (slideIndex.value === numSlides) slideIndex.value = 0;
      let nextSlideIndex = slideIndex.value;
      $(".slide-" + currentSlideIndex).fadeOut();
      $(".slide-" + nextSlideIndex).fadeIn();
      setTimeout(showSlides, 10000); // Change image every 2 seconds
    }
    onMounted(() => {
      showSlides();
    });

    return {
      nums,
      slideIndex,
    };
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}
/* Slideshow container */
.slideshow-container {
  max-width: 100%;
  position: relative;
  margin: auto;
  z-index: -1;
}
.bgSlides {
  display: none;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-size: cover;
  background-position: center;
}
</style>