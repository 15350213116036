<template>
  <teleport to="#LoginContent">
    <template v-if="!isLoggedIn && !uuid">
      <template v-if="!resetPassword">
        <div class="nk-block mt-5 pt-5 nk-block-middle nk-auth-body wide-xs">
          <div class="card card-bordered w3-round-xlarge box-shadow">
            <div class="card-inner card-inner-lg">
              <div class="nk-block-head">
                <div class="brand-logo mt-0 pb-4 text-center">
                  <a href="#" class="logo-link w3-block">
                    <img
                      class="logo-light logo-img logo-img-lg"
                      src="images/logo.png"
                      srcset="images/logo2x.png 2x"
                      alt="logo"
                    />
                    <img
                      class="logo-dark logo-img logo-img-lg"
                      src="images/logo-dark.png"
                      srcset="images/logo-dark2x.png 2x"
                      alt="logo-dark"
                    />
                  </a>
                </div>
                <div class="nk-block-head-content">
                  <h4 class="nk-block-title">Sign-In</h4>
                  <div class="nk-block-des">
                    <p>Access the portal using your email and passcode</p>
                  </div>
                </div>
              </div>
              <form @submit.prevent="logInUser" action="">
                <div class="form-group">
                  <div class="form-label-group">
                    <label class="form-label" for="default-01"
                      >Email or Username</label
                    >
                  </div>
                  <input
                    v-model="username"
                    type="text"
                    class="form-control form-control-lg"
                    id="default-01"
                    placeholder="Enter your email address or username"
                  />
                </div>
                <div class="form-group">
                  <div class="form-label-group">
                    <label class="form-label" for="password">Passcode</label>
                    <a
                      @click.prevent="resetPassword = true"
                      class="link link-primary link-sm"
                      href="#"
                      >Forgot Password?</a
                    >
                  </div>
                  <div class="form-control-wrap">
                    <a
                      href="#"
                      @click.prevent="Util.togglePassword"
                      class="form-icon form-icon-right passcode-switch"
                      data-target="password"
                    >
                      <em class="passcode-icon icon-show icon ni ni-eye"></em>
                      <em
                        class="passcode-icon icon-hide icon ni ni-eye-off"
                      ></em>
                    </a>
                    <input
                      v-model="password"
                      type="password"
                      class="form-control form-control-lg"
                      id="password"
                      placeholder="Enter your passcode"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <button class="btn btn-lg btn-primary btn-block">
                    Sign in
                  </button>
                </div>
              </form>
              <div class="form-note-s2 text-center pt-4">
                New on our platform?
                <router-link :to="{ name: 'register' }"
                  >Create an account</router-link
                >
              </div>
              <div class="text-center pt-4 pb-3">
                <h6 class="overline-title overline-title-sap">
                  <span>OR</span>
                </h6>
              </div>
              <ul class="nav justify-center gx-4">
                <li class="nav-item">
                  <a class="nav-link" href="#">Facebook</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Google</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
      <ResetPassword
        v-if="resetPassword"
        @completedPasswordReset="resetPassword = false"
        @cancelPasswordReset="resetPassword = false"
      />
    </template>
    <template v-else-if="!isMerchantSet && !muuid">
      <div class="nk-block mt-5 pt-5 nk-block-middle nk-auth-body wide-md">
        <div class="card card-bordered w3-content max-width760">
          <div class="p-4">
            <div class="card-inner-group">
              <div class="card-inner mx-0 pt-3 pb-3 mt-4">
                <div class="w3-cell-row card-title-group">
                  <div class="w3-cell w3-mobile card-title card-title-lg">
                    <h1 class="title">Join an existing company</h1>
                    <p class="size13 mb-3 pr-4">
                      You are a part of the following companies. Join the
                      company you wish to access now
                    </p>
                  </div>
                  <div class="w3-cell w3-cell-middle w3-mobile text-md-right">
                    <router-link
                      to=""
                      class="
                        btn btn-white
                        w3-theme w3-hover-theme w3-mobile
                        size13
                      "
                    >
                      <em class="icon ni ni-plus w3-text-white"></em>
                      <span class="d-sm-none d-lg-inline"
                        >Create a new company</span
                      >
                      <em
                        class="
                          d-sm-none d-lg-inline
                          dd-indc
                          w3-text-white
                          icon
                          ni ni-chevron-right
                        "
                      ></em>
                    </router-link>
                  </div>
                </div>
              </div>

              <template v-if="User?.merchants?.length">
                <div
                  v-for="merchant in User.merchants"
                  :key="merchant.uuid"
                  class="card-inner px-0 py-2 w3-hover-light-grey"
                >
                  <div class="between-start flex-wrap flex-md-nowrap px-4 pt-3">
                    <div class="nk-block-text d-md-flex">
                      <div
                        class="
                          width60
                          height60
                          mr-2
                          w3-block w3-cell w3-cell-middle w3-mobile
                        "
                      >
                        LOGO
                      </div>
                      <div class="w3-cell w3-mobile mb-3">
                        <h4 class="title m-0 size16 w3-text-theme bold">
                          {{ merchant.company_name }}
                        </h4>
                        <div class="size13">
                          Company ID: {{ merchant.merchant_id }}
                        </div>
                        <div class="size13">
                          Role: <span class="letter-spacing-2 bold">OWNER</span>
                        </div>
                      </div>
                    </div>
                    <div class="card-tools shrink-0 w3-mobile">
                      <a
                        @click.prevent="SwitchCompany(merchant.uuid)"
                        href="#"
                        class="
                          nk-block-between
                          d-inline-block
                          btn
                          w3-white
                          btn-dim btn-outline-light
                        "
                        ><span>Join</span>
                        <em
                          class="dd-indc w3-text-theme icon ni ni-chevron-right"
                        ></em>
                      </a>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="User?.roles?.length">
                <div
                  v-for="role in User.roles"
                  :key="role.uuid"
                  class="card-inner px-0 py-2 w3-hover-light-grey"
                >
                  <div class="between-start flex-wrap flex-md-nowrap px-4 pt-3">
                    <div class="nk-block-text d-md-flex">
                      <div
                        class="
                          width60
                          height60
                          mr-2
                          w3-block w3-cell w3-cell-middle w3-mobile
                        "
                      >
                        LOGO
                      </div>
                      <div class="w3-cell w3-mobile mb-3">
                        <h4 class="title m-0 size16 w3-text-theme bold">
                          {{ role.company_name }}
                        </h4>
                        <div class="size13">
                          Company ID: {{ role.merchant_id }}
                        </div>
                        <div class="size13">
                          Role:
                          <span class="letter-spacing-2 bold">{{
                            role.title.toUpperCase()
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="card-tools shrink-0 w3-mobile">
                      <a
                        @click.prevent="SwitchCompany(role.merchant_uuid)"
                        href="#"
                        class="
                          nk-block-between
                          d-inline-block
                          btn
                          w3-white
                          btn-dim btn-outline-light
                        "
                        ><span>Join</span>
                        <em
                          class="dd-indc w3-text-theme icon ni ni-chevron-right"
                        ></em>
                      </a>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="text-center pt-4">
          <a
            @click.prevent="$store.dispatch('auth/logOutUser', true)"
            href="#"
            class="4"
            ><em class="icon ni ni-back-arrow"></em> Back</a
          >
        </div>
      </div>
    </template>
  </teleport>
  <BackgroundSlider v-if="!isLoggedIn && !uuid" />
</template>

<script>
import { ref, computed, watchEffect } from "vue";
import { useStore } from "vuex";
import ResetPassword from "@/components/ResetPassword.vue";
import BackgroundSlider from "@/components/BackgroundSlider.vue";
import { appName, Util } from "@/helpers/utilities";
export default {
  components: {
    ResetPassword,
    BackgroundSlider,
  },
  setup() {
    const store = useStore();
    const User = computed(() => store.state.auth.userData);
    const isLoggedIn = computed(() => store.state.auth.isLoggedIn);
    const isMerchantSet = computed(() => store.state.auth.isMerchantSet);
    const initialized = store.state.auth.initialized;

    const isMenu = computed(() => store.getters["auth/isMenu"]);
    const appTitle = computed(() => store.state.appTitle);
    const username = ref("");
    const password = ref("");
    const rememberme = ref("");
    const resetPassword = ref(false);

    const logInUser = () => {
      store.dispatch("auth/logInUser", {
        username: username.value,
        password: password.value,
        redirect: true,
      });
    };
    const SwitchCompany = (muuid) => {
      store.dispatch("auth/switchMerchant", { muuid: muuid, redirect: true });
    };
    watchEffect(() => {
      const body = document.querySelector("body");
      if (isLoggedIn.value) {
        if (!body.classList.contains("has-apps-sidebar"))
          body.classList.add("has-apps-sidebar");
        if (isMenu.value) {
          if (!body.classList.contains("has-sidebar"))
            body.classList.add("has-sidebar");
        } else {
          if (body.classList.contains("has-sidebar"))
            body.classList.remove("has-sidebar");
        }
      } else {
        if (body.classList.contains("has-sidebar"))
          body.classList.remove("has-sidebar");
        if (body.classList.contains("has-apps-sidebar"))
          body.classList.remove("has-apps-sidebar");
      }
    });
    const uuid = localStorage.getItem(appName + "-uuid");
    const muuid = localStorage.getItem(appName + "-muuid");
    return {
      initialized,
      isLoggedIn,
      isMerchantSet,
      logInUser,
      uuid,
      muuid,
      User,
      appTitle,
      username,
      password,
      rememberme,
      resetPassword,
      Util,
      SwitchCompany,
    };
  },
};
</script>